
import { Clock } from "lucide-react";
import { Button } from "@/components/ui/button";
import { addDays, format } from "date-fns";
import { da } from "date-fns/locale";
import { PackageCard } from "./logo-packages/PackageCard";
import { packages } from "./logo-packages/packageData";
import { useNavigate } from "react-router-dom";

export const LogoPackages = () => {
  const deliveryDate = format(addDays(new Date(), 3), "d. MMMM", { locale: da });
  const navigate = useNavigate();

  return (
    <section className="py-12 bg-white relative overflow-hidden">
      <div className="absolute inset-0 bg-neutral-50 opacity-50 -z-10"></div>
      
      <div className="container mx-auto px-4 relative z-10">
        <div className="text-center mb-10">
          <h2 className="text-2xl md:text-3xl font-bold mb-3 text-primary">
            Vores logopakker
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto mb-4 text-sm">
            Fast pris uden overraskelser. Alle pakker inkluderer 100% tilfredshedsgaranti.
          </p>
          <div className="flex items-center justify-center gap-2 text-emerald-600 text-sm">
            <Clock className="w-4 h-4" />
            <p>Bestil i dag og modtag dit logo inden {deliveryDate}</p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6">
          {packages.map((pkg) => (
            <PackageCard key={pkg.name} {...pkg} />
          ))}
        </div>

        <div className="mt-10 text-center text-sm">
          <p className="text-gray-600">
            Har du spørgsmål?{" "}
            <Button 
              variant="link" 
              className="text-primary hover:text-primary/80 cursor-pointer p-0 text-sm"
              onClick={() => navigate("/kontakt")}
            >
              Kontakt os
            </Button>
          </p>
        </div>
      </div>
    </section>
  );
};
