import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { ArrowRight } from "lucide-react";
import { ContactInfo } from "./ContactInfo";

interface ContactFormFieldsProps {
  formData: {
    name: string;
    email: string;
    message: string;
  };
  isSubmitting: boolean;
  onChange: (field: string, value: string) => void;
}

export const ContactFormFields = ({ formData, isSubmitting, onChange }: ContactFormFieldsProps) => (
  <div className="space-y-6">
    <div>
      <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-2">
        Navn
      </label>
      <Input
        id="name"
        type="text"
        value={formData.name}
        onChange={(e) => onChange("name", e.target.value)}
        required
        className="w-full"
        placeholder="Dit fulde navn"
        disabled={isSubmitting}
      />
    </div>
    
    <div>
      <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-2">
        Email
      </label>
      <Input
        id="email"
        type="email"
        value={formData.email}
        onChange={(e) => onChange("email", e.target.value)}
        required
        className="w-full"
        placeholder="din@email.dk"
        disabled={isSubmitting}
      />
    </div>
    
    <div>
      <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-2">
        Besked
      </label>
      <Textarea
        id="message"
        value={formData.message}
        onChange={(e) => onChange("message", e.target.value)}
        required
        className="w-full min-h-[150px]"
        placeholder="Fortæl os om dit projekt og dine ønsker til dit nye logo..."
        disabled={isSubmitting}
      />
    </div>
    
    <Button 
      type="submit"
      className="w-full bg-gradient-to-r from-emerald-500 to-blue-500 hover:from-emerald-600 hover:to-blue-600 text-white py-6 group"
      disabled={isSubmitting}
    >
      {isSubmitting ? "Sender..." : "Send Besked"}
      <ArrowRight className="ml-2 w-5 h-5 inline-block group-hover:translate-x-1 transition-transform" />
    </Button>

    <ContactInfo />
  </div>
);