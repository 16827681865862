
import { useEffect, useRef } from 'react';
import * as d3 from 'd3';

// Define the interface for our data points
interface DataPoint {
  x: number;
  y: number;
  size: number;
}

export const D3Chart = () => {
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (!svgRef.current) return;

    // Clear any existing SVG content
    d3.select(svgRef.current).selectAll("*").remove();

    // Set up dimensions
    const width = 400;
    const height = 400;
    const margin = { top: 20, right: 20, bottom: 20, left: 20 };

    // Create SVG
    const svg = d3.select(svgRef.current)
      .attr('width', width)
      .attr('height', height)
      .style('background', 'white');

    // Generate random data with proper typing
    const numPoints = 50;
    const data: DataPoint[] = Array.from({ length: numPoints }, () => ({
      x: Math.random() * (width - margin.left - margin.right) + margin.left,
      y: Math.random() * (height - margin.top - margin.bottom) + margin.top,
      size: Math.random() * 20 + 5,
    }));

    // Create animated circles with proper typing
    svg.selectAll<SVGCircleElement, DataPoint>('circle')
      .data(data)
      .join('circle')
      .attr('cx', d => d.x)
      .attr('cy', d => d.y)
      .attr('r', 0)
      .attr('fill', () => d3.interpolateRainbow(Math.random()))
      .transition()
      .duration(1000)
      .attr('r', d => d.size)
      .transition()
      .duration(2000)
      .attr('r', d => d.size * 0.5)
      .transition()
      .duration(2000)
      .attr('r', d => d.size)
      .on('end', function repeat() {
        d3.select(this)
          .transition()
          .duration(2000)
          .attr('r', (d: DataPoint) => d.size * 0.5)
          .transition()
          .duration(2000)
          .attr('r', (d: DataPoint) => d.size)
          .on('end', repeat);
      });

    // Add interactive behavior with proper typing
    svg.selectAll<SVGCircleElement, DataPoint>('circle')
      .on('mouseover', function() {
        d3.select(this)
          .transition()
          .duration(200)
          .attr('fill', '#ff4d4d');
      })
      .on('mouseout', function() {
        d3.select(this)
          .transition()
          .duration(200)
          .attr('fill', d3.interpolateRainbow(Math.random()));
      });

  }, []);

  return (
    <div className="flex items-center justify-center w-full h-full bg-white">
      <svg ref={svgRef}></svg>
    </div>
  );
};
