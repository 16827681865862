
import { Mail, MapPin, ShieldCheck, Award, Clock } from "lucide-react";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <footer className="bg-primary text-white pt-12 pb-6">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-10">
          <div>
            <h3 className="text-lg font-medium mb-4">Logomager.dk</h3>
            <p className="text-neutral-200 mb-4 text-sm">
              Professionelt logodesign til danske virksomheder siden 2017.
            </p>
            <div className="flex items-center space-x-2 text-sm text-emerald-400">
              <Award className="w-4 h-4" />
              <span>CVR: 37892866</span>
            </div>
          </div>
          
          <div>
            <h3 className="text-lg font-medium mb-4">Links</h3>
            <ul className="space-y-2 text-sm">
              <li>
                <Link to="/" className="hover:text-emerald-400 transition-colors">Forside</Link>
              </li>
              <li>
                <Link to="/portfolio" className="hover:text-emerald-400 transition-colors">Portfolio</Link>
              </li>
              <li>
                <Link to="/priser" className="hover:text-emerald-400 transition-colors">Priser</Link>
              </li>
              <li>
                <Link to="/kontakt" className="hover:text-emerald-400 transition-colors">Kontakt</Link>
              </li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-lg font-medium mb-4">Kontakt</h3>
            <ul className="space-y-2 text-sm">
              <li className="flex items-center">
                <Mail className="w-4 h-4 mr-2 text-emerald-400" />
                <a href="mailto:logomager@magnusjuhl.com" className="hover:text-emerald-400 transition-colors">
                  logomager@magnusjuhl.com
                </a>
              </li>
              <li className="flex items-center">
                <MapPin className="w-4 h-4 mr-2 text-emerald-400" />
                <span>Amagerbrogade 253, København S</span>
              </li>
              <li className="flex items-center">
                <ShieldCheck className="w-4 h-4 mr-2 text-emerald-400" />
                <span>100% Tilfredshedsgaranti</span>
              </li>
              <li className="flex items-center">
                <Clock className="w-4 h-4 mr-2 text-emerald-400" />
                <span>Levering inden for 3 dage</span>
              </li>
            </ul>
          </div>
        </div>
        
        <div className="border-t border-neutral-700 mt-6 pt-6 text-center text-xs text-neutral-300">
          <p>&copy; {new Date().getFullYear()} Logomager.dk. Alle rettigheder forbeholdes.</p>
          <p className="mt-1">CVR-nr.: 37892866. Registreringsnummer: 6695. Kontonummer: 2000372016</p>
        </div>
      </div>
    </footer>
  );
};
