import { MessageSquare, Star } from "lucide-react";

export const Testimonials = () => {
  const testimonials = [
    {
      name: "Johannes Møller",
      company: "JM Invest",
      text: "Tak for en fantastisk indsats. Det har været en fornøjelse at arbejde sammen med jer, og resultatet er præcis som ønsket. Jeg er meget tilfreds med det endelige resultat.",
      rating: 5
    },
    {
      name: "Anne Lisbet Tøttrup",
      company: "Tøttrup Opera Rejser",
      text: "De er superfine og elegante, glæder mig til at vise dem frem og bruge dem, tak for dit flotte arbejde:-)",
      rating: 5
    },
    {
      name: "Karina Sørig",
      company: "Karinas Private Dagpleje",
      text: "Super god oplevelse! Hele processen tog mindre end en uge fra bestilling til mit logo var færdigt og lige som jeg kunne ønske mig. Fantastisk søde og hjælpsomme mennesker :-) Kan trygt anbefales :-)",
      rating: 5
    },
    {
      name: "Thomas Johansen",
      company: "Mobil Motorrens",
      text: "Det er helt perfekt :) og jeg siger mange tak for hjælpen, du hører fra mig næste gang jeg har brug for en logomager.",
      rating: 5
    },
    {
      name: "Caspar Jensen",
      company: "BrickWorks",
      text: "Fedest!!!! Du vil altid få de varmeste anbefalinger herfra!!",
      rating: 5
    },
    {
      name: "Lars Nielsen",
      company: "Nordic Design Studio",
      text: "Professionel service og et fantastisk resultat. Kan varmt anbefales til andre der søger et unikt og tidløst logo.",
      rating: 5
    }
  ];

  return (
    <section className="py-24 bg-gradient-to-b from-white to-neutral-50">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16 animate-fade-up">
          <h2 className="text-3xl md:text-4xl font-bold text-primary mb-4">
            Det siger vores kunder
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Vi er stolte af at have hjulpet over hundrede virksomheder med at skabe deres visuelle identitet. 
            Her er hvad nogle af dem siger.
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {testimonials.map((testimonial, index) => (
            <div 
              key={index}
              className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2"
            >
              <MessageSquare className="text-accent mb-4 w-8 h-8" />
              <div className="flex mb-3">
                {[...Array(testimonial.rating)].map((_, i) => (
                  <Star key={i} className="w-4 h-4 text-yellow-400 fill-current" />
                ))}
              </div>
              <p className="text-gray-600 mb-4 text-sm italic">"{testimonial.text}"</p>
              <div className="border-t pt-3">
                <p className="font-semibold text-primary text-sm">{testimonial.name}</p>
                <p className="text-sm text-gray-500">{testimonial.company}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};