import { Header } from "@/components/Header";
import { Portfolio as PortfolioSection } from "@/components/Portfolio";
import { Footer } from "@/components/Footer";

const Portfolio = () => {
  return (
    <div className="min-h-screen">
      <Header />
      <main>
        <PortfolioSection />
      </main>
      <Footer />
    </div>
  );
};

export default Portfolio;