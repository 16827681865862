
import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { Menu, X } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { Logo } from "./header/Logo";
import { DesktopNav } from "./header/DesktopNav";
import { MobileNav } from "./header/MobileNav";

export const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <header 
      className={`fixed w-full top-0 z-50 transition-all duration-300 ${
        scrolled 
          ? "bg-white shadow-sm border-b border-gray-200" 
          : "bg-white"
      }`}
    >
      <div className="container mx-auto px-4">
        <nav className="flex items-center h-16">
          <Logo />
          <DesktopNav />
          
          {/* CTA Button */}
          <div className="hidden md:flex justify-end w-1/4">
            <Button 
              onClick={() => navigate("/priser")}
              className="bg-primary hover:bg-primary/90 text-white px-4 py-1 text-sm rounded-md transition-all"
            >
              Bestil Logo
            </Button>
          </div>

          {/* Mobile Menu Button */}
          <motion.button
            className="md:hidden p-2 text-gray-600 hover:text-primary transition-colors rounded-lg hover:bg-primary/10 ml-auto"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-label={isMenuOpen ? "Close menu" : "Open menu"}
            whileTap={{ scale: 0.95 }}
          >
            {isMenuOpen ? <X size={20} /> : <Menu size={20} />}
          </motion.button>
        </nav>

        {/* Mobile Navigation */}
        <AnimatePresence>
          {isMenuOpen && (
            <MobileNav isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
          )}
        </AnimatePresence>
      </div>
    </header>
  );
};
