
import { Award, ThumbsUp, Check, ShieldCheck, Clock, HeartHandshake, Copyright } from "lucide-react";

export const packages = [
  {
    name: "Basis",
    price: "795 kr",
    paymentLink: "https://buy.stripe.com/14k28P7pXaGp2fSbII",
    features: [
      {
        text: "2 unikke logoforslag",
        tooltip: "Du får 2 forskellige designforslag at vælge imellem",
        icon: Award
      },
      {
        text: "3 korrekturrunder",
        tooltip: "Mulighed for at få lavet ændringer 3 gange",
        icon: ThumbsUp
      },
      {
        text: "Alle filformater",
        tooltip: "AI, EPS, PDF, PNG, JPG og SVG formater inkluderet",
        icon: Check
      },
      {
        text: "Fuld brugsret",
        tooltip: "Du ejer alle rettigheder til dit logo",
        icon: Copyright
      },
      {
        text: "3 dages leveringstid",
        tooltip: "Garanteret levering inden for 3 arbejdsdage",
        icon: Clock
      }
    ],
    popular: false,
    guarantees: ["100% Tilfredshedsgaranti", "Ingen skjulte gebyrer"]
  },
  {
    name: "Standard",
    price: "995 kr",
    paymentLink: "https://buy.stripe.com/cN26p539H01L3jW4gh",
    features: [
      {
        text: "4 unikke logoforslag",
        tooltip: "Du får 4 forskellige designforslag at vælge imellem",
        icon: Award
      },
      {
        text: "Ubegrænsede rettelser",
        tooltip: "Vi arbejder indtil du er 100% tilfreds",
        icon: ThumbsUp
      },
      {
        text: "Alle filformater",
        tooltip: "Optimeret til både web og tryk",
        icon: Check
      },
      {
        text: "Fuld brugsret",
        tooltip: "Du ejer alle rettigheder til dit logo",
        icon: Copyright
      },
      {
        text: "Brand guidelines",
        tooltip: "Komplet guide til brug af dit logo",
        icon: ShieldCheck
      },
      {
        text: "Visitkortdesign",
        tooltip: "Professionelt visitkort design med dit nye logo",
        icon: HeartHandshake
      },
      {
        text: "3 dages leveringstid",
        tooltip: "Garanteret levering inden for 3 arbejdsdage",
        icon: Clock
      }
    ],
    popular: true,
    guarantees: ["100% Tilfredshedsgaranti", "Ingen skjulte gebyrer", "Ubegrænset support"]
  },
  {
    name: "Premium",
    price: "1.495 kr",
    paymentLink: "https://buy.stripe.com/8wM6p58u1bKt4o05km",
    features: [
      {
        text: "6 eksklusive logoforslag",
        tooltip: "Du får 6 unikke premium designforslag",
        icon: Award
      },
      {
        text: "Ubegrænsede rettelser",
        tooltip: "Vi arbejder indtil du er 100% tilfreds",
        icon: ThumbsUp
      },
      {
        text: "Alle filformater + kildefiler",
        tooltip: "Få adgang til alle arbejdsfiler",
        icon: Check
      },
      {
        text: "Fuld brugsret",
        tooltip: "Du ejer alle rettigheder til dit logo",
        icon: Copyright
      },
      {
        text: "Komplet brand guide",
        tooltip: "Omfattende guide til din visuelle identitet",
        icon: ShieldCheck
      },
      {
        text: "Visitkort & brevpapir",
        tooltip: "Komplet pakke af tryksager",
        icon: HeartHandshake
      },
      {
        text: "SoMe-pakke",
        tooltip: "Logo tilpasset til alle sociale medier",
        icon: Check
      },
      {
        text: "3 dages leveringstid",
        tooltip: "Garanteret levering inden for 3 arbejdsdage",
        icon: Clock
      }
    ],
    popular: false,
    guarantees: ["100% Tilfredshedsgaranti", "Ingen skjulte gebyrer", "Ubegrænset support", "Pengene tilbage garanti"]
  }
];
