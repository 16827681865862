import { Link, useLocation } from "react-router-dom";

interface NavLinkProps {
  path: string;
  label: string;
}

export const NavLink = ({ path, label }: NavLinkProps) => {
  const location = useLocation();
  const isActive = location.pathname === path;

  return (
    <Link
      to={path}
      className={`font-medium transition-all duration-200 relative group py-2 ${
        isActive ? "text-primary" : "text-gray-600 hover:text-primary"
      }`}
    >
      {label}
      <span 
        className={`absolute -bottom-1 left-0 h-0.5 bg-primary transition-all duration-300 ${
          isActive ? "w-full" : "w-0 group-hover:w-full"
        }`}
      ></span>
    </Link>
  );
};