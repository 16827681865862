
import { Clock, ShieldCheck, Sparkles, CheckCircle } from "lucide-react";
import { BenefitCard } from "./BenefitCard";
import { addDays, format } from "date-fns";
import { da } from "date-fns/locale";

export const Benefits = () => {
  const deliveryDate = format(addDays(new Date(), 3), "d. MMMM", { locale: da });

  const benefits = [
    {
      icon: Clock,
      title: "Hurtig levering",
      description: `Modtag dit logo inden ${deliveryDate}`
    },
    {
      icon: ShieldCheck,
      title: "Tilfredshedsgaranti",
      description: "Vi arbejder indtil du er tilfreds"
    },
    {
      icon: Sparkles,
      title: "Dansk kvalitetsdesign",
      description: "Skræddersyet til din virksomhed"
    },
    {
      icon: CheckCircle,
      title: "Alle filformater",
      description: "CVR-registreret dansk virksomhed"
    }
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {benefits.map((benefit, index) => (
        <BenefitCard key={index} {...benefit} />
      ))}
    </div>
  );
};
