import { HelpCircle } from "lucide-react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

export const FAQ = () => {
  const faqs = [
    {
      question: "Hvordan foregår processen med at få designet et logo?",
      answer: "Processen starter med en grundig briefing, hvor vi lærer din virksomhed at kende. Derefter udvikler vi flere unikke koncepter, som du kan vælge imellem. Du får mulighed for at komme med feedback og ønsker til rettelser, indtil du er 100% tilfreds med resultatet."
    },
    {
      question: "Hvilke filformater får jeg mit logo i?",
      answer: "Du modtager dit logo i alle gængse filformater: AI, EPS, PDF, PNG, JPG og SVG. Dette sikrer, at du kan bruge dit logo i alle sammenhænge - både digitalt og til tryk. Premium-pakken inkluderer også alle kildefiler."
    },
    {
      question: "Hvor mange revisioner kan jeg få?",
      answer: "Basis-pakken inkluderer 3 revisionsrunder, mens Professionel og Premium-pakkerne tilbyder ubegrænsede revisioner. Vi arbejder sammen med dig, indtil du er helt tilfreds med resultatet."
    },
    {
      question: "Får jeg fuld ophavsret til mit logo?",
      answer: "Ja, du får komplet overdragelse af alle rettigheder til dit logo, uanset hvilken pakke du vælger. Dette betyder, at du har fuld frihed til at bruge logoet, som du ønsker."
    },
    {
      question: "Hvad er leveringstiden på et logo?",
      answer: "Vi leverer dit logo inden for 3 dage, uanset hvilken pakke du vælger. Dette gælder fra det tidspunkt, hvor vi har modtaget alle nødvendige informationer fra dig."
    },
    {
      question: "Kan I hjælpe med at redesigne mit eksisterende logo?",
      answer: "Ja, vi har stor erfaring med at modernisere eksisterende logoer. Vi bevarer de genkendelige elementer fra dit nuværende logo, mens vi opdaterer det til et mere tidssvarende design."
    }
  ];

  return (
    <section className="py-24 bg-neutral-50 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-r from-emerald-500 to-blue-500 opacity-5"></div>
      
      <div className="container mx-auto px-4 relative">
        <div className="text-center mb-16 animate-fade-up">
          <h2 className="text-3xl md:text-4xl font-bold text-primary mb-4">
            Ofte stillede spørgsmål
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Find svar på de mest almindelige spørgsmål om vores logodesign services og processer.
          </p>
        </div>
        
        <div className="max-w-3xl mx-auto">
          <Accordion type="single" collapsible className="space-y-4">
            {faqs.map((faq, index) => (
              <AccordionItem 
                key={index} 
                value={`item-${index}`} 
                className="bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-300"
              >
                <AccordionTrigger className="px-6 hover:no-underline">
                  <div className="flex items-center gap-3 text-left">
                    <HelpCircle className="text-accent w-5 h-5 flex-shrink-0" />
                    <span>{faq.question}</span>
                  </div>
                </AccordionTrigger>
                <AccordionContent className="px-6 pb-4 text-gray-600 animate-fade-up">
                  {faq.answer}
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </section>
  );
};