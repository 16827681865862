
import { Header } from "@/components/Header";
import { Footer } from "@/components/Footer";
import { D3Chart } from "@/components/D3Chart";

const D3Demo = () => {
  return (
    <div className="min-h-screen">
      <Header />
      <main>
        <section className="py-24 bg-white">
          <div className="container mx-auto px-4">
            <div className="text-center mb-16">
              <h2 className="text-3xl md:text-4xl font-bold text-primary mb-4">
                Interaktiv D3.js Visualisering
              </h2>
              <p className="text-gray-600 max-w-2xl mx-auto">
                En demonstration af vores evne til at skabe interaktive datavisualiseringer med D3.js.
                Prøv at holde musen over cirklerne!
              </p>
            </div>
            <div className="w-full max-w-2xl mx-auto bg-white rounded-lg shadow-lg p-8">
              <D3Chart />
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default D3Demo;
