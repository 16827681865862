import { Check, HelpCircle, Clock, ShieldCheck } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { PackageIcon } from "./PackageIcon";
import { LucideIcon } from "lucide-react";

interface PackageFeature {
  text: string;
  tooltip: string;
  icon: LucideIcon;
}

interface PackageProps {
  name: string;
  price: string;
  features: PackageFeature[];
  popular: boolean;
  guarantees: string[];
  paymentLink: string;
}

export const PackageCard = ({ name, price, features, popular, guarantees, paymentLink }: PackageProps) => {
  return (
    <div
      className={`relative bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 ${
        popular ? 'ring-2 ring-emerald-500' : ''
      }`}
    >
      {popular && (
        <span className="absolute -top-3 left-1/2 transform -translate-x-1/2 bg-gradient-to-r from-emerald-500 to-blue-500 text-white px-4 py-1 rounded-full text-sm font-medium">
          Mest populær
        </span>
      )}
      
      <div className="mb-8">
        <h3 className="text-2xl font-bold text-primary mb-2">{name}</h3>
        <p className="text-3xl font-bold text-emerald-600">{price}</p>
        <p className="text-gray-500 text-sm">Engangsbetaling</p>
        <div className="flex items-center gap-2 mt-4 text-emerald-600">
          <Clock className="w-4 h-4" />
          <span className="text-sm font-medium">
            Levering på 3 dage
          </span>
        </div>
      </div>

      <ul className="space-y-4 mb-8">
        {features.map((feature) => (
          <TooltipProvider key={feature.text}>
            <Tooltip>
              <TooltipTrigger asChild>
                <li className="flex items-center text-gray-600 group">
                  <PackageIcon icon={feature.icon} />
                  <span className="ml-3">{feature.text}</span>
                  <HelpCircle className="w-4 h-4 ml-2 text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity" />
                </li>
              </TooltipTrigger>
              <TooltipContent>
                <p className="text-sm">{feature.tooltip}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ))}
      </ul>

      <div className="mb-8 space-y-2">
        {guarantees.map((guarantee, index) => (
          <div key={index} className="flex items-center text-sm text-gray-600">
            <ShieldCheck className="w-4 h-4 text-emerald-500 mr-2" />
            {guarantee}
          </div>
        ))}
      </div>

      <Button 
        onClick={() => window.location.href = paymentLink}
        className={`w-full py-6 text-lg ${
          popular
            ? 'bg-gradient-to-r from-emerald-500 to-blue-500 hover:from-emerald-600 hover:to-blue-600 text-white shadow-lg'
            : 'bg-white border-2 border-primary text-primary hover:bg-primary hover:text-white'
        } transition-all duration-300 transform hover:scale-105`}
      >
        Vælg {name}
      </Button>
    </div>
  );
};