
import { Button } from "@/components/ui/button";
import { ArrowRight } from "lucide-react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

export const Hero = () => {
  const navigate = useNavigate();

  return (
    <section className="relative py-12 md:py-16 flex items-center justify-center overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-r from-primary/5 to-blue-500/5"></div>
      
      <div className="container mx-auto px-4 relative">
        <div className="grid md:grid-cols-2 gap-8 items-center">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-left"
          >
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4 leading-tight text-primary">
              Professionelt logodesign til din virksomhed
            </h1>
            
            <p className="text-base md:text-lg text-gray-600 mb-6 leading-relaxed">
              Vi designer unikke og professionelle logoer, der styrker din 
              virksomheds identitet og skaber genkendelighed hos dine kunder.
            </p>

            <div className="flex flex-wrap gap-3 mb-8">
              <span className="bg-neutral-100 px-3 py-1 text-sm rounded-full text-gray-700 flex items-center">
                <span className="w-2 h-2 bg-emerald-500 rounded-full mr-2"></span>
                100% Tilfredshedsgaranti
              </span>
              <span className="bg-neutral-100 px-3 py-1 text-sm rounded-full text-gray-700 flex items-center">
                <span className="w-2 h-2 bg-emerald-500 rounded-full mr-2"></span>
                Levering inden for 3 dage
              </span>
            </div>

            <div className="flex flex-wrap gap-4">
              <Button 
                onClick={() => navigate("/priser")}
                className="bg-primary hover:bg-primary/90 text-white px-5 py-2 text-sm rounded-md transition-all"
              >
                Se vores priser
                <ArrowRight className="ml-2 w-4 h-4" />
              </Button>
              <Button 
                variant="outline"
                onClick={() => navigate("/portfolio")}
                className="px-5 py-2 text-sm rounded-md border border-gray-300 text-gray-700 hover:bg-gray-50"
              >
                Se vores arbejde
              </Button>
            </div>
          </motion.div>
          
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="flex justify-center md:justify-end"
          >
            <div className="relative w-full max-w-md">
              <div className="absolute -inset-1 bg-gradient-to-r from-primary/20 to-blue-500/20 rounded-lg blur-lg"></div>
              <div className="relative bg-white rounded-lg shadow-md p-6">
                <div className="flex justify-center mb-4">
                  <img 
                    src="/lovable-uploads/8d1131a5-591e-4ed1-99df-c60628a9e589.png" 
                    alt="Logomager.dk" 
                    className="h-12 w-auto" 
                  />
                </div>
                <div className="space-y-3">
                  <div className="bg-neutral-100 p-3 rounded-md">
                    <h3 className="font-medium text-primary text-sm">Mere end 100+ tilfredse kunder</h3>
                    <p className="text-sm text-gray-600">CVR-registreret siden 2017</p>
                  </div>
                  <div className="bg-neutral-100 p-3 rounded-md">
                    <h3 className="font-medium text-primary text-sm">Garanti for tilfredshed</h3>
                    <p className="text-sm text-gray-600">Vi arbejder indtil du er tilfreds</p>
                  </div>
                  <div className="text-center mt-4">
                    <Button 
                      onClick={() => navigate("/kontakt")}
                      variant="link"
                      className="text-primary hover:text-primary/80 text-sm"
                    >
                      Kontakt os for mere information
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};
