// This file is automatically generated. Do not edit it directly.
import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const SUPABASE_URL = "https://ruvzpfzabmalafjuhlnc.supabase.co";
const SUPABASE_PUBLISHABLE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJ1dnpwZnphYm1hbGFmanVobG5jIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzU5Mzc3ODMsImV4cCI6MjA1MTUxMzc4M30._AdlT7uGAi5ga8o_8n5J90NvYt1PO3ltUWsgBOX8oPo";

// Import the supabase client like this:
// import { supabase } from "@/integrations/supabase/client";

export const supabase = createClient<Database>(SUPABASE_URL, SUPABASE_PUBLISHABLE_KEY);