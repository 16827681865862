import { Header } from "@/components/Header";
import { LogoPackages } from "@/components/LogoPackages";
import { Footer } from "@/components/Footer";

const Pricing = () => {
  return (
    <div className="min-h-screen">
      <Header />
      <main>
        <LogoPackages />
      </main>
      <Footer />
    </div>
  );
};

export default Pricing;