import { ShieldCheck, Clock, ThumbsUp, HeartHandshake } from "lucide-react";

export const Guarantees = () => {
  const guarantees = [
    {
      icon: <ShieldCheck className="w-8 h-8 text-accent" />,
      title: "Tilfredshedsgaranti",
      description: "Vi arbejder indtil du er fuldstændig tilfreds med dit nye logo."
    },
    {
      icon: <Clock className="w-8 h-8 text-accent" />,
      title: "Hurtig levering",
      description: "Garanteret levering inden for den aftalte tid eller pengene tilbage."
    },
    {
      icon: <ThumbsUp className="w-8 h-8 text-accent" />,
      title: "Kvalitetsgaranti",
      description: "Professionelt design der lever op til moderne standarder."
    },
    {
      icon: <HeartHandshake className="w-8 h-8 text-accent" />,
      title: "Support garanti",
      description: "Ubegrænset support efter levering af dit logo."
    }
  ];

  return (
    <section className="py-24 bg-gradient-to-b from-neutral-100 to-white relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-r from-emerald-500 to-blue-500 opacity-5"></div>
      
      <div className="container mx-auto px-4 relative">
        <div className="text-center mb-16 animate-fade-up">
          <h2 className="text-3xl md:text-4xl font-bold text-primary mb-4">
            Vores garantier til dig
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Vi står inde for kvaliteten af vores arbejde med stærke garantier.
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {guarantees.map((guarantee, index) => (
            <div 
              key={index}
              className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 animate-fade-up"
              style={{ animationDelay: `${index * 100}ms` }}
            >
              <div className="mb-6 transform hover:scale-110 transition-transform">{guarantee.icon}</div>
              <h3 className="text-xl font-bold text-primary mb-4">{guarantee.title}</h3>
              <p className="text-gray-600">{guarantee.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};