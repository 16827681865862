import { Link } from "react-router-dom";
import { NavLink } from "./NavLink";
import { navLinks } from "./navConfig";

export const DesktopNav = () => (
  <div className="hidden md:flex flex-grow justify-center w-1/2">
    <div className="flex items-center space-x-10">
      {navLinks.map((link) => (
        <NavLink key={link.path} {...link} />
      ))}
    </div>
  </div>
);