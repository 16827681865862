import { Header } from "@/components/Header";
import { Footer } from "@/components/Footer";
import { useEffect } from "react";
import { CheckCircle } from "lucide-react";

const OrderCompleted = () => {
  useEffect(() => {
    // Trigger Google Ads conversion tracking
    if (typeof window !== 'undefined' && (window as any).gtag) {
      (window as any).gtag('event', 'conversion', {
        'send_to': 'AW-1003664894/HhasCObGzJcaEP7ryt4D',
        'transaction_id': ''
      });
    }
  }, []);

  return (
    <div className="min-h-screen">
      <Header />
      <main className="container mx-auto px-4 py-24">
        <div className="max-w-2xl mx-auto text-center">
          <div className="flex justify-center mb-6">
            <CheckCircle className="w-16 h-16 text-emerald-500" />
          </div>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">
            Tak for din bestilling!
          </h1>
          <p className="text-gray-600 text-lg mb-8">
            Vi har modtaget din ordre og vil begynde arbejdet på dit logo hurtigst muligt.
            Du vil modtage en bekræftelse på email inden for kort tid.
          </p>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default OrderCompleted;