import { motion, AnimatePresence } from "framer-motion";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { AspectRatio } from "@/components/ui/aspect-ratio";
import { Button } from "@/components/ui/button";
import { ChevronDown } from "lucide-react";
import { useState, useRef } from "react";

export const Portfolio = () => {
  const [showAll, setShowAll] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);
  const ITEMS_PER_ROW = 5;
  const INITIAL_ROWS = 3;
  const FADE_ROWS = 1;

  const { data: portfolioItems = [], isLoading } = useQuery({
    queryKey: ['portfolio-images'],
    queryFn: async () => {
      const { data: files, error } = await supabase
        .storage
        .from('portfolio')
        .list();

      if (error) {
        console.error('Error fetching portfolio images:', error);
        return [];
      }

      const processedFiles = files.map(file => {
        const nameParts = file.name.split('-');
        const priority = parseInt(nameParts[0]);
        const title = nameParts.slice(1).join('-').split('.')[0].replace(/-/g, ' ');
        
        return {
          title,
          priority: isNaN(priority) ? 999 : priority,
          image: supabase.storage.from('portfolio').getPublicUrl(file.name).data.publicUrl
        };
      });

      return processedFiles.sort((a, b) => a.priority - b.priority);
    }
  });

  const handleToggleShow = () => {
    const buttonPosition = buttonRef.current?.getBoundingClientRect().top;
    
    setShowAll(!showAll);

    if (buttonPosition && buttonRef.current) {
      requestAnimationFrame(() => {
        const newButtonPosition = buttonRef.current?.getBoundingClientRect().top;
        const scrollAdjustment = newButtonPosition - buttonPosition;
        window.scrollBy(0, scrollAdjustment);
      });
    }
  };

  if (isLoading) {
    return (
      <section className="py-24 bg-white">
        <div className="container mx-auto px-4">
          <div className="text-center">
            <p>Indlæser portfolio...</p>
          </div>
        </div>
      </section>
    );
  }

  const visibleItems = portfolioItems.slice(0, ITEMS_PER_ROW * (INITIAL_ROWS + FADE_ROWS));
  const displayedItems = showAll ? portfolioItems : visibleItems;
  const hasMoreItems = portfolioItems.length > ITEMS_PER_ROW * INITIAL_ROWS;

  return (
    <section className="py-24 bg-white">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-primary mb-4">
            Udvalgte Projekter
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Se hvordan vi har hjulpet andre virksomheder med at skabe stærke visuelle identiteter gennem professionelt logodesign.
          </p>
        </div>
        
        <div className="relative">
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
            <AnimatePresence mode="sync" initial={false}>
              {displayedItems.map((item, index) => (
                <motion.div 
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, transition: { duration: 0 } }}
                  transition={showAll ? {
                    duration: 0.2,
                    delay: index * 0.05,
                    ease: "easeOut"
                  } : {
                    duration: 0
                  }}
                  className={`group relative overflow-hidden rounded-lg shadow-md hover:shadow-lg transition-all duration-300 transform hover:scale-105 ${
                    !showAll && index >= ITEMS_PER_ROW * INITIAL_ROWS ? 'opacity-60' : ''
                  }`}
                >
                  <AspectRatio ratio={1}>
                    <img 
                      src={item.image} 
                      alt={item.title}
                      className="w-full h-full object-contain bg-white"
                    />
                  </AspectRatio>
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
          
          {!showAll && hasMoreItems && (
            <div className="absolute bottom-0 left-0 right-0 h-full max-h-[calc(100%/3)] bg-gradient-to-t from-white via-white/80 to-transparent pointer-events-none" />
          )}
        </div>

        {hasMoreItems && (
          <motion.div 
            ref={buttonRef}
            className={`relative ${!showAll ? '-mt-24' : 'mt-12'} text-center z-10`}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
          >
            <Button
              onClick={handleToggleShow}
              variant="outline"
              size="lg"
              className="min-w-[140px] h-12 px-6 gap-2 text-base font-medium bg-white"
            >
              <span>{showAll ? 'Vis mindre' : 'Vis flere'}</span>
              <ChevronDown 
                className={`h-4 w-4 transition-transform duration-200 ${
                  showAll ? 'rotate-180' : ''
                }`}
              />
            </Button>
          </motion.div>
        )}
      </div>
    </section>
  );
};