import { Mail } from "lucide-react";

export const ContactInfo = () => (
  <div className="text-center space-y-2">
    <p className="text-sm text-gray-500">
      Vi vender tilbage inden for 24 timer på hverdage
    </p>
    <div className="flex items-center justify-center gap-2 text-emerald-600">
      <Mail className="w-4 h-4" />
      <a href="mailto:logomager@magnusjuhl.com" className="text-sm hover:underline">
        logomager@magnusjuhl.com
      </a>
    </div>
  </div>
);