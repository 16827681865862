import { useState } from "react";
import { toast } from "sonner";
import { MessageSquare } from "lucide-react";
import emailjs from '@emailjs/browser';
import { Benefits } from "./contact/Benefits";
import { ContactFormFields } from "./contact/ContactFormFields";

export const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await emailjs.send(
        'service_jpnnu3r',
        'template_ehpmlzu',
        {
          from_name: formData.name,
          from_email: formData.email,
          message: formData.message,
          email: formData.email, // Ensuring email is passed explicitly
        },
        'y1FPn-3CgIF2Z9dqm'
      );

      toast.success("Tak for din besked. Vi vender tilbage snart.");
      setFormData({ name: "", email: "", message: "" });
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error("Der opstod en fejl. Prøv venligst igen eller send os en email direkte.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (field: string, value: string) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  return (
    <section id="contact-form" className="py-24 bg-gradient-to-b from-neutral-50 to-white">
      <div className="container mx-auto px-4">
        <div className="max-w-6xl mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
            <div>
              <h2 className="text-3xl md:text-4xl font-bold mb-6 bg-gradient-to-r from-emerald-500 to-blue-500 bg-clip-text text-transparent">
                Få dit professionelle logo
              </h2>
              
              <p className="text-gray-600 mb-8 text-lg">
                Vi er specialister i at skabe unikke og mindeværdige logoer, der styrker din virksomheds identitet.
                Fortæl os om dit projekt, og lad os starte rejsen mod et stærkere brand sammen.
              </p>
              
              <Benefits />

              <p className="text-sm text-gray-500 mt-6">
                * Fra vi har modtaget nødvendig information om logoet
              </p>
            </div>
            
            <div>
              <form onSubmit={handleSubmit} className="bg-white p-8 rounded-xl shadow-lg border border-neutral-100">
                <div className="flex items-center gap-2 mb-8">
                  <MessageSquare className="w-6 h-6 text-emerald-500" />
                  <h3 className="text-2xl font-semibold">Fortæl os om dit projekt</h3>
                </div>

                <ContactFormFields 
                  formData={formData}
                  isSubmitting={isSubmitting}
                  onChange={handleChange}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};