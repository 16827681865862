import { ShoppingCart, PenTool, Truck } from "lucide-react";
import { motion } from "framer-motion";

export const Process = () => {
  const steps = [
    {
      icon: <ShoppingCart className="w-12 h-12 text-primary" />,
      title: "Bestilling",
      description: "Vælg den pakke der passer til dig og udfyld en lille brief om dine ønsker og behov."
    },
    {
      icon: <PenTool className="w-12 h-12 text-primary" />,
      title: "Design",
      description: "Når vi har den nødvendige forståelse af dine ønsker og info, designer vi udkast til dit logo."
    },
    {
      icon: <Truck className="w-12 h-12 text-primary" />,
      title: "Levering",
      description: "Du modtager udkast til dit logo inden for 3 dage. Derefter kan vi revidere, så du bliver helt tilfreds."
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  return (
    <section className="py-24 bg-white relative overflow-hidden">
      {/* Background pattern */}
      <div 
        className="absolute inset-0 opacity-[0.02] pointer-events-none" 
        style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23000000' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/svg%3E")`,
          transform: 'rotate(30deg) scale(2)',
          filter: 'blur(1px)'
        }}
      />
      
      <div className="container mx-auto px-4 relative">
        <motion.div 
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-100px" }}
          variants={containerVariants}
          className="text-center mb-20"
        >
          <h2 className="text-4xl md:text-5xl font-bold text-primary mb-6 tracking-tight">
            Simpel proces, professionelt resultat
          </h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Fra bestilling til levering – vi guider dig gennem hele processen med personlig service.
          </p>
        </motion.div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-16 relative">
          {steps.map((step, index) => (
            <motion.div 
              key={index}
              variants={itemVariants}
              className="relative group"
            >
              <div className="bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
                <div className="mb-8 transform group-hover:scale-110 transition-transform duration-300 flex justify-center">
                  {step.icon}
                </div>
                <h3 className="text-2xl font-bold text-primary mb-4">{step.title}</h3>
                <p className="text-gray-600 leading-relaxed">{step.description}</p>
                
                {/* Connecting lines between steps */}
                {index < steps.length - 1 && (
                  <div className="hidden md:block absolute top-1/3 -right-8 w-16 border-t-2 border-dashed border-primary/20" />
                )}
              </div>
              
              {/* Number indicator */}
              <div className="absolute -top-6 left-8 w-12 h-12 bg-primary text-white rounded-full flex items-center justify-center font-bold text-xl shadow-lg transform transition-transform group-hover:scale-110">
                {index + 1}
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};