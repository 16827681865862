import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { NavLink } from "./NavLink";
import { navLinks } from "./navConfig";

interface MobileNavProps {
  isOpen: boolean;
  onClose: () => void;
}

export const MobileNav = ({ isOpen, onClose }: MobileNavProps) => {
  const navigate = useNavigate();

  return (
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "auto" }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 0.2 }}
      className="md:hidden overflow-hidden bg-white/95 backdrop-blur-md rounded-b-2xl shadow-lg"
    >
      <div className="py-4 space-y-3">
        {navLinks.map((link, index) => (
          <motion.div
            key={link.path}
            initial={{ x: -20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.2, delay: index * 0.05 }}
          >
            <Link
              to={link.path}
              className="block font-medium transition-all duration-200 px-4 py-2 text-gray-600 hover:text-primary hover:bg-primary/5"
              onClick={onClose}
            >
              {link.label}
            </Link>
          </motion.div>
        ))}
        <motion.div
          initial={{ x: -20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.2, delay: navLinks.length * 0.05 }}
          className="px-4 pt-2"
        >
          <Button 
            onClick={() => {
              navigate("/priser");
              onClose();
            }}
            className="w-full bg-gradient-to-r from-emerald-500 to-blue-500 hover:from-emerald-600 hover:to-blue-600 text-white shadow-md hover:shadow-lg transition-all duration-300"
          >
            Bestil Logo
          </Button>
        </motion.div>
      </div>
    </motion.div>
  );
};