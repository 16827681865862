
export const About = () => {
  return (
    <section className="py-12 bg-neutral-50">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-2xl md:text-3xl font-bold text-primary mb-6 text-center">
            Professionel logodesign til danske virksomheder
          </h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="space-y-4">
              <p className="text-gray-700 leading-relaxed">
                Vi har siden 2017 hjulpet danske virksomheder med at skabe 
                professionelle logoer og visuelle identiteter, der styrker deres
                brand og øger genkendeligheden.
              </p>
              <p className="text-gray-700 leading-relaxed">
                Vores erfarne team af designere sikrer, at du får et logo, der 
                afspejler din virksomheds værdier og vision - til en fast, lav pris 
                og med garanti for tilfredshed.
              </p>
            </div>
            <div>
              <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
                <h3 className="text-lg font-semibold text-primary mb-4">Vi tilbyder</h3>
                <ul className="space-y-2 text-gray-700 text-sm">
                  <li className="flex items-center">
                    <span className="w-1.5 h-1.5 bg-emerald-500 rounded-full mr-2"></span>
                    Fast, lav pris uden overraskelser
                  </li>
                  <li className="flex items-center">
                    <span className="w-1.5 h-1.5 bg-emerald-500 rounded-full mr-2"></span>
                    Hurtig levering inden for 3 arbejdsdage
                  </li>
                  <li className="flex items-center">
                    <span className="w-1.5 h-1.5 bg-emerald-500 rounded-full mr-2"></span>
                    Ubegrænsede rettelser indtil du er tilfreds
                  </li>
                  <li className="flex items-center">
                    <span className="w-1.5 h-1.5 bg-emerald-500 rounded-full mr-2"></span>
                    Alle filformater til både web og tryk
                  </li>
                  <li className="flex items-center">
                    <span className="w-1.5 h-1.5 bg-emerald-500 rounded-full mr-2"></span>
                    CVR-registreret dansk virksomhed
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
